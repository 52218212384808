<template>
  <el-dialog
    title="分配机构"
    :visible.sync="dialogVisible"
    width="720px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCancel"
  >
    <el-form ref="paramsFormRef" :rules="rules" label-width="140px" :model="paramsForm">
      <el-form-item label="机构名称" prop="name">
        <el-input v-model="paramsForm.name"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <span slot="label"><span style="margin-right: 4px; color: #f5222d; font-size: 14px">*</span>统一社会信用代码</span>
        <el-input v-model="paramsForm.code"></el-input>
      </el-form-item>
      <el-form-item label="管理员姓名" prop="adminName">
        <el-input v-model="paramsForm.adminName"></el-input>
      </el-form-item>
      <el-form-item prop="phone">
        <span slot="label"><span style="margin-right: 4px; color: #f5222d; font-size: 14px">*</span>管理员电话</span>
        <el-input v-model="paramsForm.phone"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addOrg, editOrg } from '@/api/admin/institution'
export default {
  name: "orgDialog",
  data(){
    // 统一社会信用代码校验
    let validateCode = (rule, value, callback) => {
      const codeReg = /^[A-Za-z0-9]{1,50}$/
      if (!value) {
        return callback(new Error('统一社会信用代码不能为空'));
      }else if(!codeReg.test(value)){
        callback(new Error('请输入正确的统一社会信用代码'));
      }else{
        callback()
      }
    };
    // 检验手机号
    let validatePhone = (rule, value, callback) => {
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    return{
      dialogVisible: false,
      submitLoading: false,
      paramsForm:{
        name: '',
        code: '',
        adminName: '',
        phone: '',
      },
      rules:{
        name:[
          { required: true, message: '请输入', trigger: 'blur' },
          { max: 50, message: '长度限制在50个字符以内', trigger: 'blur' }
        ],
        code:[
          { validator: validateCode, trigger: 'blur' }
        ],
        adminName:[
          { required: true, message: '请输入', trigger: 'blur' },
          { max: 10, message: '长度限制在10个字符以内', trigger: 'blur' }
        ],
        phone:[
          { validator: validatePhone, trigger: 'blur' }
        ],
      }
    }
  },
  methods:{
    open(row,noticePhone) {
      this.dialogVisible = true;
      this.paramsForm.noticePhone = noticePhone
      if(row){
        this.$nextTick(() => {
          this.paramsForm = Object.assign(this.paramsForm, {
            id: row.id,
            name: row.name,
            code: row.code,
            adminName: row.adminName,
            phone: row.phone
          });
        })
      }
    },
    onSubmit(){
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        this.submitLoading = true
        const { id } = this.paramsForm;
        let url = id ? editOrg(this.paramsForm) : addOrg(this.paramsForm)
        url.then(() => {
          this.$message.success(id ? '编辑成功！' : '新增机构成功！');
          this.$emit('onSuccess', id);
          this.onCancel();
        }).finally(() => {
          this.submitLoading = false;
        });
      });
    },
    onCancel(){
      this.dialogVisible = false
      this.$refs.paramsFormRef.resetFields();
      this.paramsForm.id = '';
    }
  }
}
</script>

<style scoped>

</style>
